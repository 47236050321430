import React, { lazy } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

// project imports
import MainLayout from './../layout/MainLayout';
import Loadable from '../ui-component/Loadable';

// dashboard routing
const Jobs = Loadable(lazy(() => import('../views/dashboard/Jobs')));
const DashboardDefault = Loadable(lazy(() => import('../views/dashboard/Default')));
const JobTitles = Loadable(lazy(() => import('../views/dashboard/JobTitles')));

//-----------------------|| MAIN ROUTING ||-----------------------//

const MainRoutes = () => {
    const location = useLocation();

    return (
        <Route
            path={[
                '/dashboard/default',
                '/dashboard/jobs',
                '/dashboard/job-titles',

                // '/utils/util-typography',
                // '/utils/util-color',
                // '/utils/util-shadow',
                // '/icons/tabler-icons',
                // '/icons/material-icons',

                // '/sample-page'
            ]}
        >
            <MainLayout>
                <Switch location={location} key={location.pathname}>
                    
                        <Route path="/dashboard/default" component={DashboardDefault} />
                        <Route path="/dashboard/jobs" component={Jobs} />
                        <Route path="/dashboard/job-titles" component={JobTitles} />

                        {/* <Route path="/utils/util-typography" component={UtilsTypography} />
                        <Route path="/utils/util-color" component={UtilsColor} />
                        <Route path="/utils/util-shadow" component={UtilsShadow} />
                        <Route path="/icons/tabler-icons" component={UtilsTablerIcons} />
                        <Route path="/icons/material-icons" component={UtilsMaterialIcons} />

                        <Route path="/sample-page" component={SamplePage} /> */}
                    
                </Switch>
            </MainLayout>
        </Route>
    );
};

export default MainRoutes;

// third-party
import { createSlice } from '@reduxjs/toolkit';
import { debounce } from 'lodash';

// project imports
// import axios from 'utils/axios';
import axios from 'axios';
import { dispatch } from '../index';
import { openSnackbar } from './snackbar';
import configData from '../../config';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    titles: [],
    title: null,
    pageSize: 50,
    page: 0,
    keyword: '',
    count: 0,
    next: null,
    previous: null,
    isLoading: true,
    category: "",
    categories: [{id:"CO", label: "Central Office"}, {id: "DISTRICT", label: "District Office"}, {id:"CAMPUS", label: "Campus"}, {id:"HES", label: "HES"}],
};

const slice = createSlice({
    name: 'title',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
            state.isLoading = false;
        },

        // GET JOB
        getJobTitleSuccess(state, action) {
            state.title = action.payload;
            state.isLoading = false;
        },

        // GET JOBS
        getJobTitlesSuccess(state, action) {
            state.titles = action.payload;
            state.isLoading = false;
        },

        // SET LOADING
        setLoadingSuccess(state, action){
            state.isLoading = action.payload
        },

        // SET KEYWORD
        setKeywordSuccess(state, action){
            state.keyword = action.payload
        },

        // SET Page
        setPageSuccess(state, action){
            state.page = action.payload
            state.isLoading = false;
        },

        // GET Category
        getCategorySuccess(state, action) {
            state.category = action.payload;
            state.isLoading = false;
        },

        // GET CATEGORIES
        getCategoriesSuccess(state, action) {
            state.categories = action.payload;
            state.isLoading = false;
        },

    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------


let debouncedGetJTs = null;

// Create a function that delays the request using the debounce function
const performGetJTs = async (keyword, category, dispatch) => {
    try {
        dispatch(slice.actions.setLoadingSuccess(true));

        const validatedCategory = category ? category : "";
        const url = configData.API_SERVER +'job-title?&search=' + keyword + '&location=' + validatedCategory;
        const response = await axios.get(url,  { headers: { Authorization: 'Token ' + configData.TOKEN } }); // this token can be found in auttoken_token table. if it doesn't exist. create one.
        dispatch(slice.actions.getJobTitlesSuccess(response.data));
        dispatch(slice.actions.setKeywordSuccess(keyword));
        dispatch(slice.actions.getCategorySuccess(validatedCategory));
    } catch (error) {
        // Error
        dispatch(slice.actions.setLoadingSuccess(false));
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data.message);
            // console.log(error.response.status);
            // console.log(error.response.headers);
            dispatch(slice.actions.hasError(error.response.data.message));
            dispatch(
                openSnackbar({
                    open: true,
                    // eslint-disable-next-line prefer-template
                    message: error.response.data.message,
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    }
                })
            );
        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the
            // browser and an instance of
            // http.ClientRequest in node.js
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'The request was made but no response was received',
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    }
                })
            );
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Something happened in setting up the request that triggered an error',
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    }
                })
            );
        }
    }
};

// Export the nested function
export function getJobTitles(keyword, category) {
    return async (dispatch) => {
        // If keyword is empty, perform the request immediately without debounce
        if (keyword === '') {
            performGetJTs(keyword, category, dispatch);
        } else {
            // If debounce function is not defined or debounce interval changed, define it
            if (!debouncedGetJTs) {
                debouncedGetJTs = debounce(performGetJTs, 700); // 500 ms gecikme süresi
            }
            // Call the debounced function
            debouncedGetJTs(keyword, category, dispatch);
        }
    };
}
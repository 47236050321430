import { dashboard } from './dashboard';

//-----------------------|| MENU ITEMS ||-----------------------//

const menuItems = {
    items: [dashboard]
    // items: [dashboard, utilities, other]
};

export default menuItems;

// assets
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import StarRateOutlinedIcon from '@mui/icons-material/StarRateOutlined';
import QuizOutlinedIcon from '@mui/icons-material/QuizOutlined';
import PriceCheckOutlinedIcon from '@mui/icons-material/PriceCheckOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import PersonSearchOutlinedIcon from '@mui/icons-material/PersonSearchOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';


//-----------------------|| DASHBOARD MENU ITEMS ||-----------------------//

export const dashboard = {
    id: 'dashboard',
    // title: 'Dashboard',
    type: 'group',
    children: [
        {
            id: 'home',
            title: 'Home Page',
            type: 'item',
            url: 'https://harmonytx.org/',
            icon: HomeOutlinedIcon,
            external: true,
            // target: true
            visible: true
        },
        {
            id: 'jobs',
            title: 'Employment Opportunities',
            type: 'item',
            url: '/dashboard/jobs',
            icon: PersonSearchOutlinedIcon,
            breadcrumbs: false,
            visible: true
        },
        {
            id: 'job-titles',
            title: 'Job Titles',
            type: 'item',
            url: '/dashboard/job-titles',
            icon: PersonSearchOutlinedIcon,
            breadcrumbs: true,
            visible: false
        },
        {
            id: 'job-fairs',
            title: 'Job Fairs',
            type: 'item',
            url: 'https://harmonytx.org/careers/job-fairs',
            icon: CalendarMonthOutlinedIcon,
            external: true,
            visible: true
            // target: true
        },
        {
            id: 'salary-schedules',
            title: 'Salary Schedules',
            type: 'item',
            url: 'https://www.harmonytx.org/careers/salary-schedule',
            icon: PriceCheckOutlinedIcon,
            external: true,
            visible: true
            // target: true
        },
        {
            id: 'faq',
            title: 'Candidate FAQ',
            type: 'item',
            url: 'https://harmonytx.org/careers',
            icon: QuizOutlinedIcon,
            external: true,
            visible: true
            // target: true
        },
        {
            id: 'gyot',
            title: 'Grow Your Own Teacher',
            type: 'item',
            url: 'https://harmonytx.org/careers/grow-your-own-teacher',
            icon: SchoolOutlinedIcon,
            external: true,
            visible: true
            // target: true
        },
        {
            id: 'benefits',
            title: 'Benefits',
            type: 'item',
            url: 'https://harmonytx.org/careers/benefits',
            icon: StarRateOutlinedIcon,
            external: true,
            visible: true
            // target: true
        },
        {
            id: 'employees',
            title: 'Employees',
            type: 'item',
            url: 'https://harmonytx.org/employees',
            icon: BadgeOutlinedIcon,
            external: true,
            visible: true
            // target: true
        }
    ]
};

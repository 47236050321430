import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// reducer import
import customizationReducer from './customizationReducer';
import accountReducer from './accountReducer';
import jobReducer from './slices/job';
import titleReducer from './slices/title';
import snackbarReducer from './slices/snackbar';

//-----------------------|| COMBINE REDUCER ||-----------------------//

const reducer = combineReducers({
    account: persistReducer(
        {
            key: 'account',
            storage,
            keyPrefix: 'berry-'
        },
        accountReducer
    ),
    customization: customizationReducer,
    job: jobReducer,
    title: titleReducer,
    snackbar: snackbarReducer,
});

export default reducer;

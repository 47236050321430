// third-party
import { createSlice } from '@reduxjs/toolkit';
import { debounce } from 'lodash';

// project imports
// import axios from 'utils/axios';
import axios from 'axios';
import { dispatch } from '../index';
import { openSnackbar } from './snackbar';
import configData from '../../config';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    jobs: [],
    job: null,
    pageSize: 50,
    page: 0,
    keyword: '',
    count: 0,
    next: null,
    previous: null,
    isLoading: true,
    type: null,
    types: [],
    city: "",
    cities: ["Austin", "Beaumont", "Brownsville", "Bryan", "Dallas/Fort Worth", "El Paso", "Houston", "Laredo", "Lubbock", "Midland","Odessa", "San Antonio", "Waco"],
    campus: null,
    campuses: [],
    category: "",
    categories: [{id:"TEAC", label: "Teacher"}, {id: "PARA", label: "Paraprofessional"}, {id:"PROF", label: "Professional"}, {id:"SUBS", label: "Substitute"}],
};

const slice = createSlice({
    name: 'job',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
            state.isLoading = false;
        },

        // GET JOB
        getJobSuccess(state, action) {
            state.job = action.payload;
            state.isLoading = false;
        },

        // GET JOBS
        getJobsSuccess(state, action) {
            state.jobs = action.payload;
            state.isLoading = false;
        },

        // SET LOADING
        setLoadingSuccess(state, action){
            state.isLoading = action.payload
        },

        // SET KEYWORD
        setKeywordSuccess(state, action){
            state.keyword = action.payload
        },

        // SET Page
        setPageSuccess(state, action){
            state.page = action.payload
            state.isLoading = false;
        },

        // GET Type
        getTypeSuccess(state, action) {
            state.type = action.payload;
            state.isLoading = false;
        },

        // GET Types
        getTypesSuccess(state, action) {
            state.models = action.payload;
            state.isLoading = false;
        },

        // GET CITY
        getCitySuccess(state, action) {
            state.city = action.payload;
            state.isLoading = false;
        },

        // GET CITIES
        getCitiesSuccess(state, action) {
            state.cities = action.payload;
            state.isLoading = false;
        },

        // GET CAMPUS
        getCampusSuccess(state, action) {
            state.campus = action.payload;
            state.isLoading = false;
        },

        // GET CAMPUSES
        getCampusesSuccess(state, action) {
            state.campuses = action.payload;
            state.isLoading = false;
        },

        // GET Category
        getCategorySuccess(state, action) {
            state.category = action.payload;
            state.isLoading = false;
        },

        // GET CATEGORIES
        getCategoriesSuccess(state, action) {
            state.categories = action.payload;
            state.isLoading = false;
        },

    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------


let debouncedGetJobs = null;

// Create a function that delays the request using the debounce function
const performGetJobs = async (type, keyword, category, city, dispatch) => {
    try {
        dispatch(slice.actions.setLoadingSuccess(true));
        const validatedCategory = category ? category : "";
        const validatedCity = city ? city : "";
        const url = configData.API_SERVER + 'job?type=' + type +'&search=' + keyword + '&category=' + validatedCategory + '&city=' + validatedCity;
        const response = await axios.get(url,  { headers: { Authorization: 'Token ' + configData.TOKEN } }); // this token can be found in auttoken_token table. if it doesn't exist. create one.
        dispatch(slice.actions.getJobsSuccess(response.data));
        dispatch(slice.actions.setKeywordSuccess(keyword));
        dispatch(slice.actions.getCategorySuccess(validatedCategory));
        dispatch(slice.actions.getCitySuccess(validatedCity));
    } catch (error) {
        // Error
        dispatch(slice.actions.hasError(error));
        // Error
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            // console.log(error.response.status);
            // console.log(error.response.headers);
            dispatch(slice.actions.hasError(error.response.data));
            dispatch(
                openSnackbar({
                    open: true,
                    // eslint-disable-next-line prefer-template
                    message: error.response.data.message,
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    }
                })
            );
        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the
            // browser and an instance of
            // http.ClientRequest in node.js
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'The request was made but no response was received',
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    }
                })
            );
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Something happened in setting up the request that triggered an error',
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    }
                })
            );
        }
    }
};

// Export the nested function
export function getJobs(type, keyword, category, city) {
    return async (dispatch) => {
        // If keyword is empty, perform the request immediately without debounce
        if (keyword === '') {
            // Cancel any pending debounce requests
            if (debouncedGetJobs) {
                debouncedGetJobs.cancel();
            }
            debouncedGetJobs = null;
            performGetJobs(type, keyword, category, city, dispatch);
        } else {
            // If debounce function is not defined or debounce interval changed, define it
            if (!debouncedGetJobs) {
                debouncedGetJobs = debounce(performGetJobs, 700); // 700 ms delay
            }
            // Call the debounced function
            debouncedGetJobs(type, keyword, category, city, dispatch);
        }
    };
}
